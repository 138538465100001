import { makeAutoObservable } from "mobx"

class SiteSettingsClass {
  constructor() {
    this.loaded = false
    this.price = 0

    this.setData = (data) => {
      this.price = data.price || 0
      this.loaded = true
    }

    makeAutoObservable(this)
  }
}

export const SiteSettings = new SiteSettingsClass()

import './Header.css'

import { Link } from 'react-router-dom'

import Logo from './logo.png'

const Header = () => (
  <div className='container-fluid header'>
    <Link to='/'>
      <img className='logo' src={Logo} alt='' />
    </Link>
  </div>
)

export default Header

import './DeviceItem.css'

import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { ReactComponent as PenIcon } from '../../view/icon/pen.svg'
import { ReactComponent as TrashIcon } from '../../view/icon/trash.svg'

/**
 * @param {{
 *   item: import('../../store/Devices').Device;
 *   onDelete: (id: string) => void;
 *   showEdit: boolean;
 * }} props
 */
const DeviceItem = ({ item, onDelete, showEdit }) => {
  const handleDelete = useCallback(() => {
    onDelete && onDelete(item.id)
  }, [item.id, onDelete])

  return (
    <div className='d-item'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-auto'>
            <h1>
              {item.name || '【名称未設定】'}
            </h1>
            <i>{item.id}</i>
          </div>
          <div className='col-auto action-col'>
            {showEdit && <PenIcon />}
            <TrashIcon onClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(DeviceItem)

import { Functions } from '../firebase'

const deleteDevice = Functions.httpsCallable('deleteDevice')
const restoreDevice = Functions.httpsCallable('restoreDevice')

/**
 * @param {string} deviceId
 */
export const FnDeleteDevice = (deviceId) => {
  return deleteDevice({ deviceId })
}

/**
 * @param {string} deviceId
 */
export const FnRestoreDevice = (deviceId) => {
  return restoreDevice({ deviceId })
}

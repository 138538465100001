import { useCallback, useEffect, useState } from 'react'

/**
 * @param {number} seconds
 * @returns {[number, () => {}]}
 */
export const useTimer = (seconds) => {
  const [sec, setSec] = useState(-1)
  const [intervalRef, setIntervalRef] = useState()

  useEffect(() => {
    return () => {
      clearInterval(intervalRef)
    }
  }, [intervalRef])

  const start = useCallback(() => {
    setSec(seconds)
    setIntervalRef(setInterval(() => {
      setSec(sec => {
        if (sec === 1) {
          setIntervalRef(null)
        }
        return sec - 1
      })
    }, 1000))
  }, [seconds])

  return [sec < 0 ? seconds : sec, start]
}

import { makeAutoObservable } from 'mobx'
import CollectionStore, { Pagination } from './CollectionStore'

export class Device {
  constructor(id, { name, createdAt }) {
    /** @type {string} */
    this.id = id || ''
    /** @type {string} */
    this.name = name || ''

    this.createdAt = createdAt || null

    makeAutoObservable(this)
  }
}

/**
 * @param {string} uid user id.
 */
export const createDevicesStore = (uid) => {
  const store = new CollectionStore(`users/${uid}/devices`, Device)
  const pagination = new Pagination(store, 'createdAt')

  return {
    store,
    pagination,
  }
}

export const createBlacklistStore = (uid) => {
  const store = new CollectionStore(`users/${uid}/blacklist`, Device)
  const pagination = new Pagination(store, 'createdAt')

  return {
    store,
    pagination,
  }
}

import { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import UserInfo from '../../store/UserInfo'
import { usePaymentStatus } from './usePaymentStatus'
import { FnRegisterCredit, _generateFrdKey } from '../../api/PaidJpApi'
import { useInputValue } from '../../hook/UseInputValue'
import { SiteSettings } from '../../store/SiteSettings'
import { _generateShortIdWithCheck } from '../../util/ShortId'
import { Firestore } from '../../firebase'

const SHORT_ID_CHARS = 20

const BuyWidget = () => {
  const history = useHistory()

  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const [count, handleCountChange, , countRef] = useInputValue(0, e => {
    // only accept non-negative integer value
    let newV = parseInt(e.target.value) || 0
    if (newV < 0) newV = 0
    return newV
  })

  // payment registration record
  // NOTE: only support paid.jp for now
  const [record, regStatus] = usePaymentStatus()

  const handleChangeDeviceCount = useCallback(() => {
    setStep(1)
  }, [])

  const handleCancel = useCallback(() => {
    setStep(0)
  }, [])

  const handleManageDevice = useCallback(() => {
    history.push('/device')
  }, [history])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    setLoading(true)

    const frdKey = await _generateFrdKey()

    const b2bCreditId = await _generateShortIdWithCheck(SHORT_ID_CHARS, async (id) => {
      const snapshot = await Firestore.collection('bills').doc(id).get()
      return snapshot.exists
    })

    const deviceCount = countRef.current

    const data = {
      b2bMemberId: record.b2bMemberId,
      contents: `受付セットｘ${deviceCount}`,
      // code: '1234567', // not needed
      note: `アプリ利用月額・受付セットｘ${deviceCount}`,
      price: `${SiteSettings.price * deviceCount}`,
      b2bCreditId: b2bCreditId,
      frdKey,
    }

    const res = await FnRegisterCredit(data)
    const success = res.data

    if (success) {
      // create bill record: userId, b2bCreditId, price, count
      await Firestore.collection('bills').doc(b2bCreditId).set({
        userId: UserInfo.uid,
        price: SiteSettings.price,
        deviceCount,
        // user will be billed on this same date every month
        billingDate: new Date().getDate(),
      })

      // change allowed device count
      await UserInfo.updateUserInfo({
        deviceCount,
      })
      UserInfo.advance.updateData({ deviceCount })
    } else {
      window.alert('決済手続きでエラーが発生しました。')
    }

    setStep(0)
    setLoading(false)
  }, [countRef, record.b2bMemberId])

  return (
    <div className='mt-3'>
      現在の受付セット数：
      <span className='me-3'><b>{UserInfo.advance.deviceCount}</b></span>
      {regStatus === undefined ? (
        <span className='text-info'>処理中。。。</span>
      ) : (
        regStatus === 4 ? (
          <p className='text-danger'>Paidのご利用は不可です。お問い合わせください。</p>
        ) : (
          regStatus === 1 ? (
            <p className='text-success'>ただ今Paidの登録が審査中です。審査完了までにしばらくお待ちください。</p>
          ) : (
            <>
              <span className={step === 0 ? '' : 'hidden disabled'}>
                <span className='text-link' onClick={handleChangeDeviceCount}>
                  {UserInfo.advance.deviceCount > 0 ? '変更' : '購入'}
                </span>
                {UserInfo.advance.deviceCount > 0 && (
                  <span className='text-link ms-2' onClick={handleManageDevice}>
                    {'端末管理'}
                  </span>
                )}
              </span>
              <form className={`mt-3${step === 1 || step === 2 ? '' : ' hidden disabled'}`}
                onSubmit={handleSubmit}>
                <div className='form-group mb-3'>
                  {/* <span className='text-danger'>※</span> */}
                  <label htmlFor='deviceCount' className='form-label'>希望の端末数：</label>
                  <input type='number' className='form-control form-control-sm' id='deviceCount'
                    value={count} onChange={handleCountChange} />
                  {SiteSettings.loaded ? (
                    <label className='form-label text-info'>{`金額：${SiteSettings.price * count}円/月`}</label>
                  ) : (
                    <label className='form-label text-secondary'>値段情報を習得しています。。。</label>
                  )}
                </div>
                <div className={loading ? 'disabled' : ''}>
                  <button type='submit' className='btn-block btn btn-primary'
                    disabled={!SiteSettings.loaded || UserInfo.advance.deviceCount === count}>
                    登録
                  </button>
                  <button type='button' className='btn-block btn btn-outline-secondary'
                    onClick={handleCancel}>
                    キャンセル
                  </button>
                </div>
              </form>
            </>
          )
        )
      )}
    </div>
  )
}

export default observer(BuyWidget)

import { makeAutoObservable, observable } from 'mobx'

import firebase, { Firestore } from '../firebase'

/**
 * @typedef {import('../firebase').default.firestore.CollectionReference} CollectionReference
 * 
 * @typedef {[
 *   field: string,
 *   value: any,
 *   operator?: string,
 * ]} WhereInput
 */

/**
 * @template T
 */
class CollectionStore {
  /**
   * @param {string} collectionName
   * @param {T} ModelClass
   * @param {boolean} needTimestamps
   */
  constructor(collectionName, ModelClass, needTimestamps = false) {
    this.loaded = false
    /** @type {Map<string, ModelClass>} */
    this.data = observable.map()

    // create base collection ref
    const paths = collectionName.split('/')
    if (paths.length % 2 !== 1) {
      console.warn(`Invalid path length (${paths.length}) in path "${collectionName}"`)
    }

    let isCollection = false
    let dbRef = Firestore.collection(paths[0])
    for (let i = 1; i < paths.length; i++) {
      if (isCollection) {
        dbRef = dbRef.collection(paths[i])
      } else {
        dbRef = dbRef.doc(paths[i])
      }
      isCollection = !isCollection
    }

    /**
     * @param {string} id
     * @param {T} data
     * @returns {T}
     */
    this.createObject = (id, data) => {
      return new ModelClass(id, data)
    }

    /**
     * @param {CollectionReference} ref
     * @return {CollectionReference}
     */
    this.filter = (ref) => {
      return ref
    }

    this.getDbRef = () => {
      return this.filter(dbRef)
    }

    let cancelOnSnapshot = null
    this.startSync = () => {
      if (cancelOnSnapshot) {
        // already syncing
        return
      }

      console.log(`[${collectionName}] start syncing`)
      this.data.clear()
      this.loaded = false

      // start syncing
      cancelOnSnapshot = this.filter(dbRef).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.setDataLocal(change.doc.id, change.doc.data())
          }
          if (change.type === 'modified') {
            this.updateDataLocal(change.doc.id, change.doc.data())
          }
          if (change.type === 'removed') {
            this.deleteDataLocal(change.doc.id)
          }
        })
        this.setLoaded(true)
      })
    }

    this.stopSync = () => {
      if (cancelOnSnapshot) {
        console.log(`[${collectionName}] stop syncing`)

        cancelOnSnapshot()
        cancelOnSnapshot = null
      }
    }

    this.getAll = async () => {
      this.loaded = false
      const data = await this.filter(dbRef).get()
      const objs = []
      data.forEach((doc) => {
        objs.push(new ModelClass(doc.id, doc.data()))
      })
      this.setAllData(objs)
    }

    /* Actions to modify local store */

    this.setAllData = (objs) => {
      this.data.clear()
      objs.forEach((obj) => {
        this.data.set(obj.id, obj)
      })
      this.loaded = true
    }

    /**
     * @param {string} id
     * @param {T} data
     */
    this.setDataLocal = (id, data) => {
      this.data.set(id, new ModelClass(id, data))
    }

    this.updateDataLocal = (id, data) => {
      const item = this.data.get(id)
      item.setData(data)
    }

    this.deleteDataLocal = (id) => {
      this.data.delete(id)
    }

    /* Utility methods for modifying collection on Firestore */

    this.create = (data) => {
      if (needTimestamps) {
        data = {
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      }
      return dbRef.add(data)
    }

    this.createOrReplace = (id, data) => {
      if (needTimestamps) {
        data = {
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      }
      return dbRef.doc(id).set(data)
    }

    this.update = (id, data) => {
      if (needTimestamps) {
        data = {
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      }
      return dbRef.doc(id).update(data)
    }

    this.delete = (id) => {
      return dbRef.doc(id).delete()
    }

    /* Utility methods for querying data on Firestore */

    /**
     * @param {string} id
     */
    this.findById = (id) => {
      return this.filter(dbRef).doc(id).get()
    }

    /**
     * @param {...WhereInput} filters
     * @returns {Promise<T[]>}
     */
    this.findBy = async (...filters) => {
      try {
        let ref = this.filter(dbRef)
        // apply all input filters
        filters.forEach((/** @type {WhereInput} */whereInput) => {
          ref = ref.where(whereInput[0], whereInput[2] || '==', whereInput[1])
        })
        const querySnapshot = await ref.get()
        const res = []

        querySnapshot.forEach((doc) => {
          res.push(new ModelClass(doc.id, doc.data()))
        })

        return res
      } catch (e) {
        console.warn(e)
        return []
      }
    }

    /* Other actions */

    this.setLoaded = (loaded) => {
      this.loaded = loaded
    }

    makeAutoObservable(this)
  }
}

export default CollectionStore

export class Pagination {
  itemPerPage = 10
  loading = false
  hasPrev = false
  hasNext = true
  lastReached = false
  itemCount = 0
  maxItemCount = 0

  // store current loaded items
  items = []

  /**
   * @param {CollectionStore} store
   * @param {string} orderByField
   * @param {string} order
   */
  constructor(store, orderByField, order = 'asc') {
    let firstDocument = null
    let lastDocument = null

    /**
     * @param {number} items
     */
    this.setItemPerPage = (items) => {
      if (items !== this.itemPerPage) {
        this.itemPerPage = items

        // reset data
        const loaded = !!firstDocument
        this.reset()
        if (loaded) {
          this.getNext()
        }
      }
    }

    this.reset = (next = false) => {
      this.loading = false
      this.hasPrev = false
      this.hasNext = true
      this.lastReached = false
      this.itemCount = 0
      this.maxItemCount = 0
      this.items = []
      firstDocument = null
      lastDocument = null

      if (next) {
        this.getNext()
      }
    }

    this.onNewData = () => { }

    /**
     * @param {any[]} items
     * @param {boolean} isForward
     */
    this.setData = (items, isForward) => {
      if (items.length) {
        this.itemCount += isForward ? items.length : -this.items.length

        this.items = items
        this.loading = false
        this.hasNext = !isForward || items.length >= this.itemPerPage
        if (!this.hasNext) {
          this.lastReached = true
        }
        if (this.maxItemCount < this.itemCount) {
          this.maxItemCount = this.itemCount
        }
        this.hasPrev = this.itemPerPage < this.itemCount

        this.onNewData(this.items)
      } else {
        // edge case, no more item to load
        this.hasNext = false
        this.lastReached = true
        this.loading = false
      }
    }

    this.getNext = async () => {
      if (!this.hasNext) {
        return
      }
      this.loading = true

      // load next batch of data
      let nextQuery = store.getDbRef().limit(this.itemPerPage).orderBy(orderByField, order)
      if (lastDocument) {
        nextQuery = nextQuery.startAfter(lastDocument)
      }

      const docSnapshots = await nextQuery.get()

      // extract result
      const items = []
      docSnapshots.forEach(doc => {
        items.push(store.createObject(doc.id, doc.data()))
      })

      // store the first and the last visible document
      if (items.length) {
        firstDocument = docSnapshots.docs[0]
        lastDocument = docSnapshots.docs[docSnapshots.docs.length - 1]
      }

      // update data
      this.setData(items, true)
    }

    this.getPrev = async () => {
      if (!this.hasPrev) {
        return
      }
      this.loading = true

      // load prev batch of data
      const docSnapshots = await store.getDbRef()
        .orderBy(orderByField, order)
        .endBefore(firstDocument)
        .limitToLast(this.itemPerPage)
        .get()

      // extract result
      const items = []
      docSnapshots.forEach(doc => {
        items.push(store.createObject(doc.id, doc.data()))
      })

      // store the first and the last visible document
      firstDocument = docSnapshots.docs[0]
      lastDocument = docSnapshots.docs[docSnapshots.docs.length - 1]
      // check if there are more items
      this.setData(items, false)
    }

    makeAutoObservable(this)
  }
}

import './SignUpPage.css'

import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { isValidEmail, isValidPassword } from '../util/validation'
import LoadingButton from '../view/LoadingButton'
import { Auth } from '../firebase'

/**
 * @typedef {{
 *   email?: string;
 *   password?: string;
 *   confirm?: string;
 *   name?: string;
 *   displayName?: string;
 * }} Error
 */

const ERRORS = {
  UNKNOWN: '不明なエラーが発生しました。',
  'auth/email-already-in-use': 'メールアドレスが既に存在しています。',
}

const SignUpPage = () => {
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
    confirm: '',
    name: '',
  })
  const [errors, setErrors] = useState(/** @type {Error} */({}))
  const [loading, setLoading] = useState(false)
  const [creationError, setCreateError] = useState('')

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target

    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }, [inputValues])

  const validate = useCallback(() => {
    /** @type {Error} */
    const e = {}
    if (!isValidEmail(inputValues.email)) {
      e.email = 'メールアドレス形式ではありません'
    }
    if (!isValidPassword(inputValues.password)) {
      e.password = '6文字以上、40文字以下で入力してください'
    }
    if (inputValues.password !== inputValues.confirm) {
      e.confirm = 'パスワードが一致しません'
    }
    if (!inputValues.name) {
      e.name = '使用不可能な文字が含まれています'
    }

    setErrors(e)
    return Object.values(e).length === 0
  }, [inputValues.confirm, inputValues.email, inputValues.name, inputValues.password])

  const onSubmit = useCallback((e) => {
    e.preventDefault()

    const ok = validate()
    if (ok) {
      setLoading(true)

      Auth.createUserWithEmailAndPassword(inputValues.email, inputValues.password).then((user) => {
        // update user info
        user.user.updateProfile({
          displayName: inputValues.name,
        }).finally(() => {
          setLoading(false)
        })
      }).catch((err) => {
        // show error
        setLoading(false)
        setCreateError(ERRORS[err.code] || ERRORS.UNKNOWN)
        if (!ERRORS[err.code]) {
          console.log('Account registration error', err.code)
        }
      })
    }
  }, [inputValues.email, inputValues.name, inputValues.password, validate])

  return (
    <form className='signup-page sm-content' onSubmit={onSubmit}>
      <h4 className='page-title'>アカウントの作成</h4>
      <div className='form-group'>
        {errors.email && <p className='invalid-text'>{errors.email}</p>}
        <input
          type='email'
          className='form-control mb-3'
          name='email'
          placeholder='メールアドレス'
          value={inputValues.email}
          onChange={onInputChange}
        />
      </div>
      <div className='form-group'>
        {errors.password && <p className='invalid-text'>{errors.password}</p>}
        <input
          type='password'
          className='form-control mb-3'
          name='password'
          placeholder='パスワード（6文字以上、40文字以下）'
          value={inputValues.password}
          onChange={onInputChange}
        />
      </div>
      <div className='form-group'>
        {errors.confirm && <p className='invalid-text'>{errors.confirm}</p>}
        <input
          type='password'
          className='form-control mb-3'
          name='confirm'
          placeholder='パスワードの確認'
          value={inputValues.confirm}
          onChange={onInputChange}
        />
      </div>
      <div className='form-group'>
        {errors.name && <p className='invalid-text'>{errors.name}</p>}
        <input
          type='text'
          className='form-control mb-3'
          name='name'
          placeholder='氏名'
          value={inputValues.name}
          onChange={onInputChange}
        />
      </div>
      {creationError && <p className='invalid-text'>{creationError}</p>}
      <LoadingButton isLoading={loading} classname='btn-block' big>
        アカウント作成
      </LoadingButton>
      {/* <div className='terms-and-privacy'>
        アカウントを作成すると、
        {appEnv.termsUrl ? (
          <a
            href={appEnv.termsUrl}
            className='link-primary'
            target='_blank'
            rel='noreferrer'>
            利用規約
          </a>
        ) : (
          <Link to='/terms' className='link-primary'>
            利用規約
          </Link>
        )}
        、及び
        <br />
        {appEnv.privacyUrl ? (
          <a
            href={appEnv.privacyUrl}
            className='link-primary'
            target='_blank'
            rel='noreferrer'>
            プライバシーポリシー
          </a>
        ) : (
          <Link to='/privacy-policy' className='link-primary'>
            プライバシーポリシー
          </Link>
        )}
        に同意したことになります。
      </div> */}
      <div className='text-center'>
        <Link to='/' className='link-primary'>
          サインインはこちら
        </Link>
      </div>
    </form>
  )
}

export default SignUpPage

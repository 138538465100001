function LoadingButton({ id, classname, children, style, onClick, disabled, isLoading, big }) {
  return (
    <button id={id} className={`btn btn-${style || 'primary'}${big ? '' : ' btn-sm'}${classname ? ' ' + classname : ''}`}
      onClick={onClick} disabled={disabled || isLoading}>
      {isLoading && (
        <div className='spinner-border spinner-border-sm' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      )} {children}
    </button>
  )
}

export default LoadingButton

import { useCallback, useEffect, useState } from 'react'

import { Auth } from '../../firebase'
import UserInfo from '../../store/UserInfo'

const NameChange = ({ saving = false, onSaved = () => { } }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState('')

  const onNameChange = useCallback((e) => setName(e.target.value), [])

  const validate = useCallback(() => {
    if (!name.trim()) {
      setError('氏名をご入力ください。')
      return false
    }

    setError('')
    return true
  }, [name])

  useEffect(() => {
    if (saving) {
      // start saving
      if (validate()) {
        Auth.currentUser.updateProfile({
          displayName: name.trim(),
        }).then(() => {
          onSaved && onSaved(true)
          UserInfo.setLoggedIn(true, Auth.currentUser)
        }).catch(() => {
          setError('不明なエラーが発生しました。')
          onSaved && onSaved(false)
        })
      }
    }
  }, [name, onSaved, saving, validate])

  return (
    <>
      <label className='mb-2'>現在の氏名：{UserInfo.displayName}</label>

      <div className='mb-3'>
        {error && <p className='invalid-text'>{error}</p>}
        <input
          type='text'
          className='form-control'
          placeholder='氏名'
          value={name}
          onChange={onNameChange}
        />
      </div>
    </>
  )
}

export default NameChange

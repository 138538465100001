import './HomePage.css'

import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ReactComponent as ArrowRightIcon } from '../view/icon/arrow.svg'
import UserInfo from '../store/UserInfo'
import Routes from '../Routes'
import { Auth } from '../firebase'

const HomePage = () => {
  const onLogout = useCallback(() => {
    Auth.signOut()
  }, [])

  return (
    <div className='home-page'>
      <h4>{UserInfo.displayName}</h4>
      <span>{UserInfo.email}</span><br />
      <span>デバイスの数：{UserInfo.deviceCount}</span>
      <ul className='settings-list'>
        {Routes.filter(e => !!e.homeEntry)
          // device management page only available if deviceCount > 0
          .filter(e => e.segment !== 'device' || UserInfo.deviceCount)
          .map(e => (
            <li key={e.segment}>
              <Link to={e.segment}>
                <span>{e.homeEntry}</span>
              </Link>
              <ArrowRightIcon />
            </li>
          ))}
      </ul>
      <button
        type='button'
        className='btn btn-outline-primary btn-block mt-5'
        onClick={onLogout}>
        {'サインアウト'}
      </button>
    </div>
  )
}

export default observer(HomePage)

const allowedChars = [
  ...new Array(26).fill('-').map((c, i) => String.fromCharCode('a'.charCodeAt(0) + i)),
  ...new Array(26).fill('-').map((c, i) => String.fromCharCode('A'.charCodeAt(0) + i)),
  ...new Array(10).fill('-').map((c, i) => String.fromCharCode('0'.charCodeAt(0) + i)),
]

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 * @param {number} min
 * @param {number} max
 */
export const _getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const _generateShortId = (charCount) => {
  return new Array(charCount).fill('').map(() => allowedChars[_getRandomInt(0, allowedChars.length - 1)]).join('')
}

/**
 * @callback IdCollisionCheck
 * @param {string} shortId the id to be checked
 * @return {Promise<boolean>} true if shortId exists
 */

/**
 * @param {number} charCount
 * @param {IdCollisionCheck} checkFn
 */
export const _generateShortIdWithCheck = async (charCount, checkFn) => {
  // generate short id if needed
  let shortId = ''
  let cont = false
  do {
    // generate random short id
    shortId = _generateShortId(charCount)

    cont = await checkFn(shortId)
  } while (cont)

  return shortId
}

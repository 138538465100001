import './NavBar.css'

import { Link, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import UserInfo from '../store/UserInfo'

function NavBar({ root, paths }) {
  let { segment: s1, segment2: s2 } = useParams()

  const segment = useMemo(() => !s1 && !s2 ? '' : [s1, s2].filter(s => !!s).join('/'), [s1, s2])

  const reducedSegments = useMemo(() => {
    const segments = {}
    let clickableCount = 0
    paths.forEach(p => {
      if (p.clickable) {
        // keep clickable segments as-is
        segments[clickableCount++] = [p]
      } else {
        // collapse un-clickable segments with same text
        if (!segments[p.text]) {
          segments[p.text] = []
        }
        segments[p.text].push(p)
      }
    })

    // segment map
    const map = {}
    Object.values(segments).forEach((ss, i) => ss.forEach(s => map[s.segment] = i))

    return {
      segments: Object.values(segments).map(s => s[0]),
      map,
    }
  }, [paths])

  return (
    <nav className='navbar navbar-expand-sm navbar-light bg-light mb-2'>
      <div style={{ fontWeight: 'bold' }}>
        <ul className='navbar-nav'>
          {reducedSegments.segments.filter(({ segment }) => {
            return segment !== 'device' || UserInfo.deviceCount
          }).map(({ segment: s, text, clickable }) => (
            <li
              key={s}
              className={`nav-item${reducedSegments.map[s] === reducedSegments.map[segment] ? ' active' : ''}`}>
              {clickable ? (
                <Link className='nav-link' to={`${root}/${s}`}>
                  {text}
                </Link>
              ) : (
                <div className='nav-link'>
                  {text}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default observer(NavBar)

import DeviceManagementPage from './page/DeviceManagementPage/DeviceManagementPage'
import HomePage from './page/HomePage'
import InfoChangePage from './page/InfoChangePage/InfoChangePage'
import PaymentPage from './page/PaymentPage/PaymentPage'

const Routes = [
  {
    segment: '',
    text: 'アカウント',
    component: HomePage,
    exact: true,
    clickable: true,
  },
  {
    segment: 'info/name',
    text: '情報編集',
    homeEntry: '氏名の変更',
    component: InfoChangePage,
    exact: true,
  },
  {
    segment: 'info/password',
    text: '情報編集',
    homeEntry: 'パスワードの変更',
    component: InfoChangePage,
    exact: true,
  },
  {
    segment: 'payment',
    text: '支払い',
    homeEntry: 'お支払い方法の変更',
    component: PaymentPage,
    exact: true,
    clickable: true,
  },
  {
    segment: 'device',
    text: '端末管理',
    homeEntry: '端末管理',
    component: DeviceManagementPage,
    exact: true,
    clickable: true,
  },
]

export default Routes

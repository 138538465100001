import { useCallback, useEffect, useState } from 'react'

import firebase, { Auth } from '../../firebase'
import UserInfo from '../../store/UserInfo'
import { isValidPassword } from '../../util/validation'

const PasswordChange = ({ saving = false, onSaved = () => { } }) => {
  const [inputValues, setInputValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirm: '',
  })
  const [errors, setErrors] = useState({})
  const [error, setError] = useState('')

  const validate = useCallback(() => {
    const e = {}
    if (!inputValues.oldPassword) {
      e.oldPassword = 'パスワードをご入力ください。'
    }
    if (!isValidPassword(inputValues.newPassword)) {
      e.newPassword = '6文字以上、40文字以下で入力してください。'
    }
    if (inputValues.newPassword !== inputValues.confirm) {
      e.confirm = 'パスワードが一致しません。'
    }

    setErrors(e)
    return Object.values(e).length === 0
  }, [inputValues])

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target

    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }, [inputValues])

  useEffect(() => {
    if (saving) {
      // start saving
      if (validate()) {
        // verify old password
        const cred = firebase.auth.EmailAuthProvider.credential(UserInfo.email, inputValues.oldPassword)
        Auth.currentUser.reauthenticateWithCredential(cred).then(() => {
          // old password is ok, perform password change
          Auth.currentUser.updatePassword(inputValues.newPassword).then(() => {
            onSaved && onSaved(true)
          }).catch(() => {
            setError('不明なエラーが発生しました。')
            onSaved && onSaved(false)
          })
        }).catch(() => {
          // old password is not ok
          setErrors({
            oldPassword: 'パスワードが正しくありません。',
          })
          onSaved && onSaved(false)
        })
      } else {
        onSaved && onSaved(false)
      }
    }
  }, [inputValues.newPassword, inputValues.oldPassword, onSaved, saving, validate])

  return (
    <>
      <div className='mb-5'>
        {errors.oldPassword && (
          <p className='invalid-text'>{errors.oldPassword}</p>
        )}
        <input
          type='password'
          className='form-control'
          name='oldPassword'
          placeholder='現在のパスワード'
          value={inputValues.oldPassword}
          onChange={onInputChange}
        />
        {/* <div className='forgot-password'>
          <Link to='/forgot-password' className='link-secondary'>
            パスワードを忘れた場合
          </Link>
        </div> */}
      </div>
      <div className='mb-3'>
        {errors.newPassword && (
          <p className='invalid-text'>{errors.newPassword}</p>
        )}
        <input
          type='password'
          className='form-control'
          name='newPassword'
          placeholder='新しいパスワード（6文字以上、40文字以下）'
          value={inputValues.newPassword}
          onChange={onInputChange}
        />
      </div>
      <div className='mb-3'>
        {errors.confirm && <p className='invalid-text'>{errors.confirm}</p>}
        <input
          type='password'
          className='form-control'
          name='confirm'
          placeholder='新しいパスワードの確認'
          value={inputValues.confirm}
          onChange={onInputChange}
        />
      </div>
      {error && <p className='invalid-text'>{error}</p>}
    </>
  )
}

export default PasswordChange

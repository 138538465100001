import './PaidJpPayment.css'

import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { PaidJpRegistration } from './PaidJpRegistration'
import { usePaymentStatus } from './usePaymentStatus'

const MEMBER_STATUSES = {
  'undefined': {
    text: '不明',
    style: 'secondary',
  },
  1: {
    text: '審査中',
    style: 'success',
    code: 1,
  },
  2: {
    text: '利⽤可能-取引中',
    style: 'warning',
    code: 2,
  },
  3: {
    text: '利⽤可能-取引不可',
    style: 'warning',
    code: 3,
  },
  4: {
    text: '利⽤不可',
    style: 'danger',
    code: 4,
  },
}

const PaidJpPayment = () => {
  // payment registration step
  const [regStep, setRegStep] = useState(0)

  const handleNextStep = useCallback(() => {
    setRegStep(1)
  }, [])

  const [record, regStatus] = usePaymentStatus()

  return (
    <div className='paid-payment-page'>
      {!record ? (
        <>
          {regStep === 0 ? (
            <>
              <p>
                取引先に必ず承諾を得てからご利用ください。
              </p>
              <p>（1）Paidでの請求を開始すること</p>
              <p>（2）取引先に代わり代理でPaidの登録を行うこと</p>
              <p>（3）登録後にメールか電話でPaidから連絡があること</p>
              <button className='btn-block btn btn-primary' onClick={handleNextStep}>承知しました</button>
            </>
          ) : (
            <PaidJpRegistration />
          )}
        </>
      ) : (
        <div>
          Paid登録ステータス：
          <div className={`btn btn-outline-${MEMBER_STATUSES[regStatus].style || 'primary'} btn-sm`}>
            {MEMBER_STATUSES[regStatus].text}
          </div>
          <div className='mt-3'>
            {regStatus === MEMBER_STATUSES[1].code && (
              <p>審査完了までにしばらくお待ちください。</p>
            )}
            {(regStatus === MEMBER_STATUSES[2].code || regStatus === MEMBER_STATUSES[3].code) && (
              <p>
                <span>Paidのご利用は可能です。購入は</span>
                <Link to='/payment'>
                  <span className='text-link'>こちら</span>
                </Link>
                <span>です。</span>
              </p>
            )}
            {regStatus === MEMBER_STATUSES[4].code && (
              <p className='text-danger'>Paidのご利用は不可です。お問い合わせください。</p>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(PaidJpPayment)

import './LoginPage.css'

import { useCallback, useState } from 'react'

import Logo from '../view/logo.png'
import { Auth } from '../firebase'
import LoadingButton from '../view/LoadingButton'
import UserInfo from '../store/UserInfo'
import { useTimer } from '../hook/UseTimer'

const EmailVerificationpage = () => {
  const [sending, setSending] = useState(undefined)
  const [countdown, start] = useTimer(60)
  const [error, setError] = useState(false)

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    setSending(true)

    Auth.currentUser.sendEmailVerification({
      url: window.location.protocol + '//' + window.location.host,
    }).then(() => {
      setError(false)
      setSending(false)
      start()
    }).catch(() => {
      setError(true)
      setSending(false)
      start()
    })
  }, [start])

  const onLogout = useCallback(() => {
    Auth.signOut()
  }, [])

  return (
    <div className='login-page sm-content'>
      <div className='logo'>
        <img src={Logo} alt='' />
      </div>
      <form onSubmit={onSubmit}>
        <div className='text-center'>
          {sending === undefined ? (
            'メールの確認が必要です。「認証メールを送信」ボタンをクリックしてメールアドレスを確認させてください。'
          ) : (
            sending === true ? (
              '送信しています。。。'
            ) : (
              error ? (
                <span className='invalid-text'>認証メールの送信が失敗しました。</span>
              ) : (
                `「${UserInfo.email}」に認証メールを送信しました。認証URLからメールアドレスの認証を行ってください。`
              )
            )
          )}
        </div>
        <LoadingButton isLoading={sending} disabled={sending === false && countdown} classname='btn-block' big>
          {sending !== false ? (
            '認証メールを送信'
          ) : (
            `認証メールを再度送信${countdown > 0 ? `（${countdown}）` : ''}`
          )}
        </LoadingButton>
        <button
          type='button'
          className='btn btn-outline-secondary btn-block'
          onClick={onLogout}>
          {'サインアウト'}
        </button>
      </form>
    </div>
  )
}

export default EmailVerificationpage

import { useCallback } from 'react'

import { JP_PERFECTURES } from '../../constant/Constants'
import { useInputValue } from '../../hook/UseInputValue'
import UserInfo from '../../store/UserInfo'
import { FnRegisterCustomer, _generateFrdKey } from '../../api/PaidJpApi'

const PAYMENT_OPTIONS = {
  1: {
    text: '銀行振込',
    value: 1,
  },
  2: {
    text: '⼝座振替',
    value: 2,
  },
  3: {
    text: 'コンビニ払い',
    value: 3,
  },
}

const CLOSINGDAY_OPTIONS = {
  20: {
    text: '20⽇締め',
    value: 20,
  },
  31: {
    text: '末締め',
    value: 31,
  },
}

/**
 * Force input to includes numeric characters only.
 * 
 * @param {string} input
 * @param {number} charCount
 */
const enforceNumber = (input, charCount) => {
  // remove non-numeric characters
  input = input.replace(/[^0-9]/g, '')

  // limit character count
  return input.substring(0, charCount)
}

export const PaidJpRegistration = () => {
  const [companyName, handleCompanyNameChange, , companyNameRef] = useInputValue('')
  const [companyNameKana, handleCompanyNameKanaChange, , companyNameKanaRef] = useInputValue('')

  const [representativeSei, handleRepresentativeSeiChange, , representativeSeiRef] = useInputValue('')
  const [representativeMei, handleRepresentativeMeiChange, , representativeMeiRef] = useInputValue('')
  const [representativeSeiKana, handleRepresentativeSeiKanaChange, , representativeSeiKanaRef] = useInputValue('')
  const [representativeMeiKana, handleRepresentativeMeiKanaChange, , representativeMeiKanaRef] = useInputValue('')

  const [zipCodeP1, handleZipCodeP1Change, , zipCodeP1Ref] = useInputValue('', (e) => enforceNumber(e.target.value, 3))
  const [zipCodeP2, handleZipCodeP2Change, , zipCodeP2Ref] = useInputValue('', (e) => enforceNumber(e.target.value, 4))

  const [prefecture, handlePrefectureChange, , prefectureRef] = useInputValue(-1, (e) => parseInt(e.target.value))

  const [address1, handleAddress1Change, , address1Ref] = useInputValue('')
  const [address2, handleAddress2Change, , address2Ref] = useInputValue('')

  const [auxName, handleAuxNameChange, , auxNameRef] = useInputValue('')

  const [sameAsPrep, handleSameAsPrepChange, , sameAsPrepRef] = useInputValue(false, e => e.target.checked)
  const [clerkSei, handleClerkSeiChange, , clerkSeiRef] = useInputValue('')
  const [clerkMei, handleClerkMeiChange, , clerkMeiRef] = useInputValue('')
  const [clerkSeiKana, handleClerkSeiKanaChange, , clerkSeiKanaRef] = useInputValue('')
  const [clerkMeiKana, handleClerkMeiKanaChange, , clerkMeiKanaRef] = useInputValue('')

  const [tel1, handleTel1Change, , tel1Ref] = useInputValue('', (e) => enforceNumber(e.target.value, 4))
  const [tel2, handleTel2Change, , tel2Ref] = useInputValue('', (e) => enforceNumber(e.target.value, 4))
  const [tel3, handleTel3Change, , tel3Ref] = useInputValue('', (e) => enforceNumber(e.target.value, 4))

  const [email, handleEmailChange, , emailRef] = useInputValue('')

  const [payment, handlePaymentChange, , paymentRef] = useInputValue(PAYMENT_OPTIONS[1].value, v => v)
  const [closing, handleClosingChange, , closingRef] = useInputValue(CLOSINGDAY_OPTIONS[20].value, v => v)

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()

    // generate frdKey
    const frdKey = await _generateFrdKey()

    // construct memberData object
    const sameAsPrep = sameAsPrepRef.current
    const memberData = {
      b2bMemberId: UserInfo.advance.shortId,
      companyName: companyNameRef.current,
      companyNameKana: companyNameKanaRef.current,
      representativeSei: representativeSeiRef.current,
      representativeMei: representativeMeiRef.current,
      representativeSeiKana: representativeSeiKanaRef.current,
      representativeMeiKana: representativeMeiKanaRef.current,
      zipCode: `${zipCodeP1Ref.current}-${zipCodeP2Ref.current}`,
      prefecture: JP_PERFECTURES[prefectureRef.current],
      address1: address1Ref.current,
      address2: address2Ref.current,
      auxName: auxNameRef.current,
      clerkSei: sameAsPrep ? representativeSeiRef.current : clerkSeiRef.current,
      clerkMei: sameAsPrep ? representativeMeiRef.current : clerkMeiRef.current,
      clerkSeiKana: sameAsPrep ? representativeSeiKanaRef.current : clerkSeiKanaRef.current,
      clerkMeiKana: sameAsPrep ? representativeMeiKanaRef.current : clerkMeiKanaRef.current,
      tel: `${tel1Ref.current}-${tel2Ref.current}-${tel3Ref.current}`,
      email: emailRef.current,
      payment: paymentRef.current,
      closing: closingRef.current,
      frdKey,
    }

    // console.log(memberData)

    // send data to PaidJp
    FnRegisterCustomer(memberData, {}).then((res) => {
      // console.log(res.data)
      UserInfo.advance.paymentMethods.store.getAll()
    })
  }, [
    address1Ref,
    address2Ref,
    auxNameRef,
    clerkMeiKanaRef,
    clerkMeiRef,
    clerkSeiKanaRef,
    clerkSeiRef,
    closingRef,
    companyNameKanaRef,
    companyNameRef,
    emailRef,
    paymentRef,
    prefectureRef,
    representativeMeiKanaRef,
    representativeMeiRef,
    representativeSeiKanaRef,
    representativeSeiRef,
    sameAsPrepRef,
    tel1Ref,
    tel2Ref,
    tel3Ref,
    zipCodeP1Ref,
    zipCodeP2Ref,
  ])

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label htmlFor='companyName' className='form-label main-label'>会社名</label>
        <input type='text' className='form-control form-control-sm' id='companyName'
          value={companyName} onChange={handleCompanyNameChange} />
        <label htmlFor='companyNameKana' className='form-label'>フリガナ</label>
        <input type='text' className='form-control form-control-sm' id='companyNameKana'
          value={companyNameKana} onChange={handleCompanyNameKanaChange} />
      </div>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label className='form-label main-label'>代表者名</label>
        <div className='row mb-2'>
          <div className='col-auto'><label className='col-form-label' htmlFor='representativeSei'>姓</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' id='representativeSei' type='text'
              value={representativeSei} onChange={handleRepresentativeSeiChange} />
          </div>
          <div className='col-auto'><label className='col-form-label' htmlFor='representativeMei'>名</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' id='representativeMei' type='text'
              value={representativeMei} onChange={handleRepresentativeMeiChange} />
          </div>
        </div>
        <label className='form-label'>フリガナ</label>
        <div className='row'>
          <div className='col-auto'><label className='col-form-label' htmlFor='representativeSeiKana'>セイ</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' id='representativeSeiKana' type='text'
              value={representativeSeiKana} onChange={handleRepresentativeSeiKanaChange} />
          </div>
          <div className='col-auto'><label className='col-form-label' htmlFor='representativeMeiKana'>メイ</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' id='representativeMeiKana' type='text'
              value={representativeMeiKana} onChange={handleRepresentativeMeiKanaChange} />
          </div>
        </div>
      </div>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label className='form-label main-label'>住所</label>
        <div className='row mb-3'>
          <div className='col-auto'><label className='col-form-label'>〒</label></div>
          <div className='col-3'>
            <input className='form-control form-control-sm' id='zipCodeP1' type='text'
              value={zipCodeP1} onChange={handleZipCodeP1Change} />
          </div>
          <div className='col-auto px-0'><label className='col-form-label'>-</label></div>
          <div className='col-4'>
            <input className='form-control form-control-sm' id='zipCodeP2' type='text'
              value={zipCodeP2} onChange={handleZipCodeP2Change} />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-auto'>
            <select className='form-control form-control-sm' value={prefecture} onChange={handlePrefectureChange}>
              <option disabled value={-1}>--選択してください--</option>
              {JP_PERFECTURES.map((p, i) => (
                <option key={i} value={i}>{p}</option>
              ))}
            </select>
          </div>
        </div>
        <input className='form-control form-control-sm' id='address1' type='text'
          value={address1} onChange={handleAddress1Change} />
        <div className='form-text mb-3'>例：中央区</div>
        <input className='form-control form-control-sm' id='address2' type='text'
          value={address2} onChange={handleAddress2Change} />
        <div className='form-text mb-3'>例：日本橋蠣殻町1-14-14</div>
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='auxName' className='form-label main-label'>部署名 / 支店名</label>
        <input type='text' className='form-control form-control-sm' id='auxName'
          value={auxName} onChange={handleAuxNameChange} />
      </div>
      <div className='form-group mb-3'>
        <div className='row'>
          <div className='col-auto'>
            <span className='text-danger'>※</span> <label className='form-label main-label'>担当者名</label>
          </div>
          <div className='col-auto form-check'>
            <input type='checkbox' className='form-check-input' id='sameAsRepCheck' checked={sameAsPrep} onChange={handleSameAsPrepChange} />
            <label className='form-check-label' htmlFor='sameAsRepCheck'>代表者と同じ</label>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-auto'><label className='col-form-label' htmlFor='clerkSei'>姓</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' disabled={sameAsPrep} id='clerkSei' type='text'
              value={clerkSei} onChange={handleClerkSeiChange} />
          </div>
          <div className='col-auto'><label className='col-form-label' htmlFor='clerkMei'>名</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' disabled={sameAsPrep} id='clerkMei' type='text'
              value={clerkMei} onChange={handleClerkMeiChange} />
          </div>
        </div>
        <label className='form-label'>フリガナ</label>
        <div className='row'>
          <div className='col-auto'><label className='col-form-label' htmlFor='clerkSeiKana'>セイ</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' disabled={sameAsPrep} id='clerkSeiKana' type='text'
              value={clerkSeiKana} onChange={handleClerkSeiKanaChange} />
          </div>
          <div className='col-auto'><label className='col-form-label' htmlFor='clerkMeiKana'>メイ</label></div>
          <div className='col'>
            <input className='form-control form-control-sm' disabled={sameAsPrep} id='clerkMeiKana' type='text'
              value={clerkMeiKana} onChange={handleClerkMeiKanaChange} />
          </div>
        </div>
      </div>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label className='form-label main-label'>電話番号</label>
        <div className='row mb-3'>
          <div className='col-3'>
            <input className='form-control form-control-sm' id='tel1' type='number'
              value={tel1} onChange={handleTel1Change} />
          </div>
          <div className='col-auto px-0'><label className='col-form-label'>-</label></div>
          <div className='col-3'>
            <input className='form-control form-control-sm' id='tel2' type='number'
              value={tel2} onChange={handleTel2Change} />
          </div>
          <div className='col-auto px-0'><label className='col-form-label'>-</label></div>
          <div className='col-3'>
            <input className='form-control form-control-sm' id='tel3' type='number'
              value={tel3} onChange={handleTel3Change} />
          </div>
        </div>
      </div>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label htmlFor='inputEmail' className='form-label main-label'>メールアドレス</label>
        <input type='email' className='form-control form-control-sm' id='inputEmail' aria-describedby='emailHelp'
          value={email} onChange={handleEmailChange} />
        <div id='emailHelp' className='form-text'>メールアドレスが誤っている場合、ご利用を見合わせる可能性があります。</div>
      </div>
      <div className='form-group'>
        <div className='row'>
          <div className='col-auto'>
            <span className='text-danger'>※</span> <label className='form-label main-label'>締め日</label>
          </div>
          {Object.values(CLOSINGDAY_OPTIONS).map(({ text, value }) => (
            <div key={value} className='col-auto'>
              <div className='form-check form-check-inline'>
                <input className='form-check-input' type='radio' id={`closingDay${value}`} checked={closing === value} onChange={() => handleClosingChange(value)} />
                <label className='form-check-label' htmlFor={`closingDay${value}`}>{text}</label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='form-group mb-3'>
        <span className='text-danger'>※</span> <label className='form-label main-label'>お支払い方法</label><br />
        {Object.values(PAYMENT_OPTIONS).map(({ value, text }) => (
          <div key={value} className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' id={`paymentMethod${value}`} checked={payment === value} onChange={() => handlePaymentChange(value)} />
            <label className='form-check-label' htmlFor={`paymentMethod${value}`}>{text}</label>
          </div>
        ))}
      </div>
      {/* <div className='form-group mb-3 form-check'>
        <input type='checkbox' className='form-check-input' id='exampleCheck1' />
        <label className='form-check-label' htmlFor='exampleCheck1'>Check me out</label>
      </div> */}
      <button type='submit' className='btn-block btn btn-primary'>登録</button>
    </form>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * @template T
 * @param {T} initialValue 
 * @param {(e) => T} modifier 
 * @returns {[T, (e) => void, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>]}
 */
export const useInputValue = (initialValue, modifier) => {
  const [value, setValue] = useState(initialValue)

  const valueRef = useRef(initialValue)
  useEffect(() => {
    valueRef.current = value
  }, [value])

  const onChange = useCallback((e) => {
    if (modifier) {
      setValue(modifier(e))
    } else {
      setValue(e.target.value)
    }
  }, [modifier])

  return [value, onChange, setValue, valueRef]
}

import { useEffect, useMemo, useState } from 'react'

import { FnCheckCustomerStatus } from '../../api/PaidJpApi'
import { PAYMENT_METHODS } from '../../constant/Constants'
import UserInfo from '../../store/UserInfo'

/**
 * UserInfo.advance.paymentMethods.store need to be loaded
 * in order for this hook to work properly.
 * 
 * @returns {[import('../../store/PaymentMethods').PaymentMethod, boolean]}
 */
export const usePaymentStatus = () => {
  // payment registration record
  const record = useMemo(() => {
    let r = null
    UserInfo.advance.paymentMethods.store.data.forEach((item) => {
      if (item.type === PAYMENT_METHODS.PAID) {
        r = item
      }
    })
    return r
  }, [])

  // registration status
  const [regStatus, setRegStatus] = useState(/** @type {boolean} */(undefined))

  useEffect(() => {
    let unmounted = false
    if (record) {
      FnCheckCustomerStatus(record.b2bMemberId).then((status) => {
        if (!unmounted) {
          setRegStatus(status)
        }
      })
    }

    return () => {
      unmounted = true
    }
  }, [record])

  return [record, regStatus]
}

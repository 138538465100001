import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LoadingButton from '../../view/LoadingButton'
import NameChange from './NameChange'
import PasswordChange from './PasswordChange'

const PATHS = {
  NAME: '/info/name',
  PASSWORD: '/info/password',
}

const InfoChangePage = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const [saving, setSaving] = useState(false)

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    setSaving(true)
  }, [])

  const handleInfoSaved = useCallback((ok) => {
    if (ok) {
      history.push('/')
    } else {
      setSaving(false)
    }
  }, [history])

  return (
    <div>
      <form onSubmit={onSubmit}>
        {pathname === PATHS.NAME ? (
          <NameChange saving={saving} onSaved={handleInfoSaved} />
        ) : (
          <PasswordChange saving={saving} onSaved={handleInfoSaved} />
        )}

        <LoadingButton classname='btn-block' isLoading={saving} big>
          保存
        </LoadingButton>
      </form>
    </div>
  )
}

export default InfoChangePage

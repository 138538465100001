import { makeAutoObservable } from 'mobx'
import { Firestore } from '../firebase'
import { _generateShortIdWithCheck } from '../util/ShortId'
import { createBlacklistStore, createDevicesStore } from './Devices'
import { createPaymentMethodStore } from './PaymentMethods'

const SHORT_ID_CHARS = 20

class UserInfoAdvance {
  shortId = '' // Firebase uid is too long for PaidJP which has 20 characters limit
  deviceCount = 0

  constructor(uid) {
    this.devices = createDevicesStore(uid)
    this.blacklist = createBlacklistStore(uid)
    this.paymentMethods = createPaymentMethodStore(uid)

    this.setData = (data) => {
      this.shortId = data.shortId || ''
      this.deviceCount = data.deviceCount || 0
    }

    this.updateData = (data) => {
      Object.keys(data).forEach((k) => {
        if (typeof data[k] !== 'function') {
          this[k] = data[k]
        }
      })
    }

    this.setDeviceCount = (count = 0) => {
      this.deviceCount = count
    }

    this.toJSON = () => {
      return {
        shortId: this.shortId,
      }
    }
  }
}

const USER_INFO_DEFAULT = {
  uid: '',
  loggedIn: undefined,
  displayName: '',
  email: '',
  emailVerified: '',
  advance: /** @type {UserInfoAdvance} */(null),
}

const USER_TABLE = 'users'

class UserInfo {
  constructor() {
    this.clearData = () => {
      Object.entries(USER_INFO_DEFAULT).forEach(([k, v]) => {
        this[k] = v
      })
    }
    this.clearData()

    /**
     * @param {UserInfoAdvance} data
     */
    this.setAdvancedData = (data) => {
      this.advance = data
    }

    this.updateUserInfo = (data) => {
      if (!this.uid) {
        return false
      }
      return Firestore.collection(USER_TABLE).doc(this.uid).update(data)
    }

    this.loadAdvance = async () => {
      const userRecord = await Firestore.collection(USER_TABLE).doc(this.uid).get()

      let advance = null
      if (userRecord.exists) {
        // set advanced data
        advance = new UserInfoAdvance(this.uid)
        advance.setData(userRecord.data())
      } else {
        // create empty record
        await Firestore.collection(USER_TABLE).doc(this.uid).set({})
        // need to create UserInfoAdvance after user record has been added into Firestore
        // because UserInfoAdvance will need to create sub collections for that user
        advance = new UserInfoAdvance(this.uid)
      }

      // check for short id
      if (!advance.shortId) {
        // generate short id if needed
        const shortId = await _generateShortIdWithCheck(SHORT_ID_CHARS, async (id) => {
          const record = await Firestore.collection(USER_TABLE).where('shortId', '==', id).get()
          return !record.empty
        })

        // update user record
        await this.updateUserInfo({ shortId })
        advance.updateData({ shortId })
      }

      this.setAdvancedData(advance)
    }

    makeAutoObservable(this)
  }

  get deviceCount() {
    return this.advance ? this.advance.deviceCount || 0 : 0
  }

  set deviceCount(value = 0) {
    this.advance && this.advance.setDeviceCount(value)
  }

  setLoggedIn(loggedIn, userData) {
    if (loggedIn) {

      this.loggedIn = true
      // set data
      Object.keys(this).forEach((k) => {
        if (typeof this[k] !== 'function' && k in userData) {
          this[k] = userData[k]
        }
      })

      this.loadAdvance()
    } else {
      // clear data
      this.clearData()
      this.advance = null

      // but keep logged in info
      this.loggedIn = false
    }
  }
}

/** @type {USER_INFO_DEFAULT} */
const instance = new UserInfo()

export default instance

import './Footer.css'

const Footer = () => {
  return (
    <div className='container-fluid footer'>
      <div className='row'>
        <div className='col-12 col-white text-center'>
          ©︎2020 Donut Robotics Co., Ltd.
        </div>
      </div>
    </div>
  )
}

export default Footer

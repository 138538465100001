import './PaymentPage.css'

import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import PaidJpLogo from '../../view/logo_paid_jp.png'
import UserInfo from '../../store/UserInfo'
import PaidJpPayment from './PaidJpPayment'
import { PAYMENT_METHODS } from '../../constant/Constants'
import BuyWidget from './BuyWidget'

const PaymentPage = () => {
  const history = useHistory()
  const location = useLocation()

  // selected payment method to show
  const method = useMemo(() => {
    return location.state && location.state.method
  }, [location.state])

  // load registered payment methods
  useEffect(() => {
    UserInfo.advance.paymentMethods.store.getAll()
  }, [])

  // select PaidJp payment method
  const handlePaidJpClick = useCallback(() => {
    history.push('/payment', { method: PAYMENT_METHODS.PAID })
  }, [history])

  return (
    <div className='payment-page'>
      {UserInfo.advance.paymentMethods.store.loaded ? (
        method === PAYMENT_METHODS.PAID ? (
          <PaidJpPayment />
        ) : (
          <>
            {UserInfo.advance.paymentMethods.store.data.size > 0 ? (
              <p>
                現在のお支払方法：
              </p>
            ) : (
              <p>
                お支払方法をご選択して手続きを行ってください。
              </p>
            )}
            <div className='payment-item' onClick={handlePaidJpClick}>
              <img src={PaidJpLogo} alt='PaidJP' />
            </div>

            {/* show buying options if there is a payment method */}
            {UserInfo.advance.paymentMethods.store.data.size > 0 && <BuyWidget />}
          </>
        )
      ) : (
        <span className='text-info'>
          お支払方法の情報を取得中。。。
        </span>
      )}
    </div>
  )
}

export default observer(PaymentPage)

import { observable } from 'mobx'
import { Functions } from '../firebase'

const registerCustomer = Functions.httpsCallable('registerCustomer')
const checkCustomerStatus = Functions.httpsCallable('checkCustomerStatus')
const registerCredit = Functions.httpsCallable('registerCredit')

/**
 * @typedef {{
 *   companyName?: string;
 *   companyNameKana?: string;
 *   recipientSei?: string;
 *   recipientMei?: string;
 *   recipientSeiKana?: string;
 *   recipientKana?: string;
 *   zipCode?: string;
 *   prefecture?: string;
 *   address1?: string;
 *   address2?: string;
 *   address3?: string;
 *   tel?: string;
 *   fax?: string;
 *   mobileTel?: string;
 *   email?: string;
 * }} PaidRecipientData
 * 
 * @typedef {{
 *   b2bMemberId: string;
 *   companyName: string;
 *   companyNameKana: string;
 *   representativeSei: string;
 *   representativeMei: string;
 *   representativeSeiKana: string;
 *   representativeMeiKana: string;
 *   zipCode: string;
 *   prefecture: string;
 *   address1: string;
 *   address2: string;
 *   address3?: string;
 *   auxName?: string;
 *   clerkSei: string;
 *   clerkMei: string;
 *   clerkSeiKana: string;
 *   clerkMeiKana: string;
 *   tel: string;
 *   fax?: string;
 *   mobileTel?: string;
 *   email: string;
 *   url1?: string;
 *   url2?: string;
 *   url3?: string;
 *   closingDay?: number;
 *   paymentMethod?: number;
 *   frdKey?: string;
 * }} PaidMemberData
 */

let frdKey = undefined // cache frd key

/**
 * @returns {Promise<string>}
 */
export const _generateFrdKey = async () => {
  if (!frdKey) {
    frdKey = await new Promise((resolve) => {
      if (window._paid) {
        window._paid.getFRDKey(resolve)
      } else {
        resolve(undefined)
      }
    })
  }

  return frdKey
}

/**
 * @param {PaidMemberData} memberData
 * @param {PaidRecipientData} recipientData
 * @returns 
 */
export const FnRegisterCustomer = (memberData, recipientData) => {
  return registerCustomer({ memberData, recipientData })
}

/** @type {{ [b2bMemberId: string]: number }} */
const statuses = observable.map({})

/**
 * @param  {string} b2bMemberId
 */
export const FnCheckCustomerStatus = async (b2bMemberId, refresh = false) => {
  // check cached result
  if (refresh || statuses[b2bMemberId] === undefined) {
    const res = await checkCustomerStatus({ b2bMemberIds: [b2bMemberId] })
    if (res.data && res.data.success) {
      const { results } = res.data
      statuses[b2bMemberId] = parseInt(results[b2bMemberId]) || undefined
    }
  }

  return statuses[b2bMemberId]
}

export const FnRegisterCredit = (creditData) => {
  return registerCredit({ creditData })
}

import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { FnDeleteDevice, FnRestoreDevice } from '../../api/DeviceApi'
import UserInfo from '../../store/UserInfo'
import DeviceList from './DeviceList'

const DeviceManagementPage = () => {
  const [showBlacklist, setShowBlacklist] = useState(false)

  const handleDeviceDelete = useCallback((itemId) => {
    FnDeleteDevice(itemId).then((res) => console.log(res.data))
    UserInfo.advance.devices.pagination.reset()
  }, [])

  const handleDeviceRestore = useCallback((itemId) => {
    FnRestoreDevice(itemId).then((res) => console.log(res.data))
    UserInfo.advance.devices.pagination.reset()
  }, [])

  const handleSelectChange = useCallback((e) => {
    setShowBlacklist(e.target.value === 'true')
  }, [])

  return (
    <div>
      <div className='row justify-content-between align-items-center mb-3'>
        <div className='col-auto'>
          デバイスの数：<b>{UserInfo.deviceCount}</b>
          <Link to='/payment'>
            <span className='text-link ms-3'>
              {UserInfo.advance.deviceCount > 0 ? '変更' : '購入'}
            </span>
          </Link>
        </div>
        <div className='col-auto'>
          <select className='form-control' value={showBlacklist} onChange={handleSelectChange}>
            <option value={false}>デバイス一覧</option>
            <option value={true}>ブラックリスト</option>
          </select>
        </div>
      </div>
      {showBlacklist ? (
        <DeviceList pagination={UserInfo.advance.blacklist.pagination} onItemDelete={handleDeviceRestore} />
      ) : (
        <DeviceList pagination={UserInfo.advance.devices.pagination} onItemDelete={handleDeviceDelete} showEdit />
      )}
    </div>
  )
}

export default observer(DeviceManagementPage)

import './LoginPage.css'

import { useCallback, useState } from 'react'
import { Link, BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Logo from '../view/logo.png'
import { Auth } from '../firebase'
import SignUpPage from './SignUpPage'

const ERRORS = {
  UNKNOWN: '不明なエラーが発生しました。',
  'auth/user-not-found': 'アカウントが存在していません。',
  'auth/wrong-password': 'パスワードが正しくありません。',
}

function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const onEmailInputChange = useCallback((e) => {
    setEmail(e.target.value.trim().toLowerCase())
  }, [])

  const onPasswordInputChange = useCallback((e) => {
    setPassword(e.target.value)
  }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    setError('')

    if (!email || !password) {
      setError('メールアドレスとパスワードをご入力ください。')
    } else {
      Auth.signInWithEmailAndPassword(email, password).catch((error) => {
        // sign in error
        setError(ERRORS[error.code] || ERRORS.UNKNOWN)
        if (!ERRORS[error.code]) {
          console.warn('sign in error', error.code)
        }
      })
    }
  }, [email, password])

  return (
    <div className='login-page sm-content'>
      <div className='logo'>
        <img src={Logo} alt='' />
      </div>
      <Router>
        <Switch>
          <Route path='/signup' exact>
            <SignUpPage />
          </Route>
          <Route>
            <form onSubmit={onSubmit}>
              <h4 className='col-dark-gray mb-3'>サインイン</h4>
              {error && <p className='text-center invalid-text mb-1'>{error}</p>}
              <input
                type='email'
                className='form-control mb-3'
                name='email'
                placeholder='メールアドレス'
                value={email}
                onChange={onEmailInputChange}
              />
              <input
                type='password'
                className='form-control mb-3'
                name='password'
                placeholder='パスワード'
                value={password}
                onChange={onPasswordInputChange}
              />
              <button type='submit' className='btn btn-primary btn-block'>
                サインイン
              </button>
              <div className='text-center'>
                <Link to='/signup' className='link-primary'>
                  新規登録はこちら
                </Link>
              </div>
            </form>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default LoginPage

import { makeAutoObservable } from 'mobx'

import CollectionStore, { Pagination } from './CollectionStore'

export class PaymentMethod {
  constructor(id, data) {
    /** @type {string} */
    this.id = id
    this.type = data.type

    this.setData = (data) => {
      Object.entries(data).forEach(([k, v]) => {
        if (typeof v !== 'function') {
          this[k] = v
        }
      })
    }

    this.setData(data)

    makeAutoObservable(this)
  }
}

/**
 * @param {string} uid user id.
 */
export const createPaymentMethodStore = (uid) => {
  /** @type {CollectionStore<PaymentMethod>} */
  const store = new CollectionStore(`users/${uid}/payments`, PaymentMethod, true)
  const pagination = new Pagination(store, 'createdAt')

  return {
    store,
    pagination,
  }
}

import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'

import DeviceItem from './DeviceItem'

/**
 * @param {{
 *   pagination: import('../../store/CollectionStore'.Pagination);
 *   onItemDelete: (id: string) => void;
 *   showEdit: boolean;
 * }} props
 */
const DeviceList = ({ pagination, onItemDelete, showEdit }) => {
  useEffect(() => {
    pagination.reset()
    pagination.getNext()

    return () => {
      // pagination.reset()
    }
  }, [pagination])

  const handleItemDelete = useCallback((itemId) => {
    onItemDelete && onItemDelete(itemId)
    pagination.reset()
  }, [onItemDelete, pagination])

  return (
    <>
      {pagination.loading ? (
        <span className='text-info'>
          {'データを取得しています。。。'}
        </span>
      ) : (
        pagination.items.length === 0 && (
          <span className='text-secondary'>
            {'データがありません。'}
          </span>
        )
      )}
      {pagination.items.map(item => (
        <DeviceItem key={item.id} item={item} onDelete={handleItemDelete} showEdit={showEdit} />
      ))}
    </>
  )
}

export default observer(DeviceList)

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'

// Add only the Firebase products that will be used
// import 'firebase/app-check'
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/storage'
import 'firebase/functions'

// Initialize Firebase
firebase.initializeApp({
  'apiKey': process.env.REACT_APP_FIREBASE_APIKEY,
  'authDomain': process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  'projectId': process.env.REACT_APP_FIREBASE_PROJECTID,
  'storageBucket': process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  'messagingSenderId': process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  'appId': process.env.REACT_APP_FIREBASE_APPID,
})

// App check
// if (process.env.NODE_ENV === 'production') {
//   const appCheck = firebase.appCheck()
//   appCheck.activate('6LdYbpMcAAAAACrU4DzCbT8KzT3Iy8nf3iP9tpoS', true)
// }

// Initialize Authentication module
const auth = firebase.auth()

// Initialize Firestore module
const firestore = firebase.firestore()

// Initialize Storage module
// const storage = firebase.storage()

// Initialize Functions module
const functions = firebase.functions()

// use emulator in development mode
const DOMAIN = 'localhost'
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_EMULATOR === '1') {
  auth.useEmulator(`http://${DOMAIN}:9099`)
  firestore.useEmulator(DOMAIN, 8080)
  // storage.useEmulator(DOMAIN, 9199)
  functions.useEmulator(DOMAIN, 5001)
}

export const Auth = auth
export const Firestore = firestore
// export const Storage = storage
export const Functions = functions

export default firebase

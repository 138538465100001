import './App.css'

import { useEffect } from 'react'
import { Link, Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import UserInfo from './store/UserInfo'
import Header from './view/Header'
import NavBar from './view/NavBar'
import Routes from './Routes'
import Footer from './view/Footer'
import { ReactComponent as BackIcon } from './view/icon/left.svg'
import LoginPage from './page/LoginPage'
import { Auth, Firestore } from './firebase'
import EmailVerificationpage from './page/EmailVerificationPage'
import { SiteSettings } from './store/SiteSettings'

function App() {
  useEffect(() => {
    const removeAuthStateListener = Auth.onAuthStateChanged((user) => {
      if (user) {
        UserInfo.setLoggedIn(true, user)
        // load site settings
        Firestore.collection('settings').doc('general').get().then((snapshot) => {
          if (snapshot.exists) {
            SiteSettings.setData(snapshot.data())
          }
        })
      } else {
        UserInfo.setLoggedIn(false)
      }
    })

    return () => {
      removeAuthStateListener()
    }
  }, [])

  return UserInfo.loggedIn === undefined ? (
    '処理中。。。'
  ) : (
    UserInfo.loggedIn === false ? (
      <LoginPage />
    ) : (
      UserInfo.emailVerified === false ? (
        <EmailVerificationpage />
      ) : (
        <Router>
          <Header />
          <div className='container-fluid main-content'>
            <div className='sm-content admin-page'>
              <Route path='/:segment?/:segment2?'>
                <NavBar root='' paths={Routes} />
              </Route>
              <Route path='/:segment'>
                <Link className='btn-back-link mt-3 mb-3' to='/'>
                  <button type='button' className='btn btn-outline-primary btn-icon'>
                    <BackIcon />{' ホームに戻る'}
                  </button>
                </Link>
              </Route>
              {UserInfo.advance ? (
                <Switch>
                  {Routes.map(({ component, segment, exact }) => component ? (
                    <Route key={segment} exact={!!exact} path={`/${segment}`} component={component} />
                  ) : null)}
                  <Redirect to='/' />
                </Switch>
              ) : (
                '処理中。。。'
              )}
            </div>
          </div>
          <Footer />
        </Router>
      )
    )
  )
}

export default observer(App)
